




































































import { Component, Vue, Watch } from 'vue-property-decorator';

import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import FlagshipDefineGoalsCardViewModel
  from '@/vue-app/view-models/components/my-investment-dashboard-v2/flagship-define-goals-card-view-model';

@Component({
  name: 'FlagshipTrackingGoalsCard',
  components: {
    LoadingDataCard,
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class FlagshipTrackingGoalsCard extends Vue {
  view_model = Vue.observable(new FlagshipDefineGoalsCardViewModel());

  @Watch('view_model.investor_profile_state_manager.state.investor_profile.id')
  onInvestorProfileIdChange(id: string) {
    if (id) {
      this.view_model.loadInvestorProfile();
    }
  }

  created() {
    this.view_model.loadInvestorProfile();
  }
}
